import React, { useState } from "react";
import logo from "../../images/LogoCampi.png";
import "./Header.css";
import MenuUser from "../Menu/MenuUser";

const Header = ({ user, signOut }) => {
  const [openMenu, setOpenMenu] = useState(null);

  const closeMenus = () => {
    setOpenMenu(null);
  };

  return (
    <div className="header-container">
      <header className="header">
        <div className="left-section">
          <div className="logo-container">
            <a href="/">
              <img src={logo} alt="Logo" className="logo" />
            </a>{" "}
            
          </div>
          <div className="separator-menu">
            
          </div>
          <ul className="menu-links">
              <li>
                <a href="/sync">Sincronizar</a>
              </li>

            </ul>
            <ul className="menu-links">
            <li>
                <a href="/images">Importar imágenes</a>
              </li>
              
            </ul>
            <ul className="menu-links">
            <li>
                <a href="/views">Ver imágenes</a>
              </li>
              
            </ul>
        </div>

        <div className="center-section"></div>

        <div className="right-section">

 
        </div>
        {/* Botón Sign Out fuera del menú */}
        <button className="logout-button" onClick={signOut}>
          Cerrar sesión
        </button>
      </header>
      {openMenu === "user" && (
        <MenuUser user={user} signOut={signOut} closeMenu={closeMenus} />
      )}
      
    </div>

    
  );
};

export default Header;
