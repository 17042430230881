// src/components/Sync/MessageSync.js
import React, { useEffect } from "react";
import "./Sync.css";

const MessageSync = ({ onClose }) => {
  // Maneja el clic fuera del popup para cerrarlo
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (event.target.className === 'message-sync') {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  return (
    <div className="message-sync">
      <button className="close-btn" onClick={onClose}>X</button>
      <p>¡Se están sincronizando los productos! Toma un cafecito mientras termina la sincronización! ☕</p>
    </div>
  );
};

export default MessageSync;
