import React, { useState, useEffect } from 'react';
import { Amplify } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import awsExports from '../../aws-exports';
import { useNavigate } from 'react-router-dom';
import LogoHeader from './LogoHeader';
import Footer from './Footer';
import './LoginForm.css';

Amplify.configure(awsExports);

function LoginForm({ setUser }) {
  const navigate = useNavigate();
  const [authUser, setAuthUser] = useState(null);

  useEffect(() => {
    if (authUser) {
      setUser(authUser);
      navigate('/sync'); 
    }
  }, [authUser, navigate, setUser]);

  return (
    <Authenticator
      signUpAttributes={[]}
      components={{
        Header: LogoHeader,
        Footer: Footer,
      }}
    >
      {({ signOut, user }) => {
        if (user && !authUser) {
          setAuthUser(user);
        }

        return (
          <>
          <br/>
            <h1>Hola {user?.username}</h1>
            <button onClick={signOut} className='amplify-button amplify-button--primary'>Cerrar sesión</button>
          </>
        );
      }}
    </Authenticator>
  );
}

export default LoginForm;
