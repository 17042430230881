import React from 'react';
import logo from '../../images/LogoCampi.png';



const Logo = () => (
  <div style={{ textAlign: 'center', marginBottom: '20px' }}>
        <img src={logo} alt="Logo" className="logo-login" />
  </div>
);

export default Logo;