import React, { useState } from "react";
import axios from "axios";
import "./Sync.css";
import MessageSync from "./MessageSync";

const Sync = () => {
  const [loading, setLoading] = useState(false);  
  const [counter, setCounter] = useState(0); 
  const [showMessage, setShowMessage] = useState(false);

  const handleFullSync = async () => {
    setLoading(true); 
    setCounter(0);  
    setShowMessage(false);

    try {
      // URL de la Lambda
      const url = "https://h3acfowa7pbaqtrklgl6ehgojm0cxuhn.lambda-url.us-east-1.on.aws/";

      // Cuerpo (payload) que quieres enviar a la Lambda
      const payload = { key: "value" };

      // Realizar la solicitud POST a la Lambda
      const response = await axios.post(url, payload);

      console.log("Respuesta de Lambda:", response.data);
      setShowMessage(true);

    } catch (error) {
      console.error("Error al ejecutar la Lambda:", error.message);
    } finally {
      setLoading(false);  
    }

    // Contador de carga
    const interval = setInterval(() => {
      setCounter((prevCounter) => prevCounter + 1);
    }, 1000);

    setTimeout(() => {
      clearInterval(interval);
      setLoading(false);
    }, 10000); 
  };

  return (
    <div className="contenedor-document">
      <div className="contenedor-botones">
        <button
          type="button"
          className="btn-submit"
          onClick={handleFullSync}
          disabled={loading}
        >
          {loading ? `Sincronizando... ${counter}s` : "Sincronización FULL"}
        </button>
        <small>Esta función se recomienda solo cuando se necesite sincronizar <strong>todos</strong> los productos. Si solo deseas actualizar, por favor utilizar <i>Actualizar productos</i></small>
      </div>

      {showMessage && <MessageSync onClose={() => setShowMessage(false)} />}
    </div>
  );
};

export default Sync;
