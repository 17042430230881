// src/components/Footer.js
import React from 'react';


const Footer = () => (
  <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <div className="login-form-policy">
            Al continuar, acepta nuestros Términos de uso, Aviso de privacidad, Aviso de cookies y Aviso de anuncios basados ​​en intereses.       </div>

      <footer className="login-footer">
        <p>© 2024, Campi Ferreteria, CA.</p>
      </footer>


  </div>
);

export default Footer;
