import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Amplify } from "aws-amplify";
import { withAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import LogoHeader from "./components/Login/LogoHeader";
import Footer from "./components/Login/Footer";
import awsExports from "./aws-exports";
import LoginForm from "./components/Login/LoginForm";
import Header from "./components/Header/Header";
import Sync from "./components/Sync/Sync";
import UploadImages from "./components/Images/UploadImages";
import SeeImages from "./components/Images/SeeImages";

Amplify.configure(awsExports);

function App({ signOut, user }) {
  const [localUser, setLocalUser] = useState(user);

  useEffect(() => {
    if (user) {
      setLocalUser(user);
    }
  }, [user]);
  
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  return (
    <Router>
      <div className="app-container">
        {localUser && <Header user={localUser} signOut={signOut} />}
        <div className="main-content">
          {localUser && <h1>Hola  {user?.username ? capitalizeFirstLetter(user.username) : ""}</h1>}
          <Routes>
            <Route
              path="/login"
              element={<LoginForm setUser={setLocalUser} />}
            />

            <Route path="/sync" element={<Sync user={localUser} />} />
            <Route path="/images" element={<UploadImages user={localUser} />} />
            <Route path="/views" element={<SeeImages user={localUser} />} />

            <Route path="*" element={<LoginForm setUser={setLocalUser} />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default withAuthenticator(App, {
  components: {
    Header: LogoHeader,
    Footer: Footer,
  },
});
