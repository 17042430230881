import React, { useEffect, useState } from "react";
import Select from "react-select";

const ReadProduct = ({ categoryId, onProductChange }) => {
  const [products, setProducts] = useState([]);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (categoryId) {
      fetch("https://1e01glq9n4.execute-api.us-east-1.amazonaws.com/readproduct")
        .then((response) => response.json())
        .then((data) => {
          // Asegúrate de acceder al array de productos
          const filteredProducts = data.products.filter((product) => 
            String(product.category_id) === String(categoryId)
          );

          // Formateamos los productos para ser usados en react-select
          const productOptions = filteredProducts.map(product => ({
            value: product.product_id,
            label: product.product_name,
          }));

          setProducts(filteredProducts);
          setOptions(productOptions);
        })
        .catch((error) => console.error("Error fetching products:", error));
    } else {
      setProducts([]);
      setOptions([]);
    }
  }, [categoryId]);

  const handleChange = (selectedOption) => {
    // Verificamos que selectedOption no sea null antes de acceder a sus propiedades
    if (selectedOption) {
      const selectedProduct = products.find(prod => prod.product_id === selectedOption.value);
      if (selectedProduct) {
        onProductChange({ name: selectedProduct.product_name, id: selectedProduct.product_id });
      }
    } else {
      // Si el select se limpia, no pasar datos erróneos
      onProductChange(null);
    }
  };

  return (
    <div className="form-group">
      <label htmlFor="product">Productos:</label>
      <Select
        id="product"
        options={options}
        onChange={handleChange}
        placeholder="Selecciona un producto"
        isClearable
        isSearchable
        className="form-control"
      />
    </div>
  );
};

export default ReadProduct;
