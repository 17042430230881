import React, { useState, useRef } from "react";
import ReadCategories from './ReadCategories';
import ReadProduct from './ReadProduct';
import "./UploadImages.css";

const UploadImages = ({ user }) => {
  const [files, setFiles] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [selectedProduct, setSelectedProduct] = useState({});
  const [isDragging, setIsDragging] = useState(false);
  const fileInputRef = useRef(null);

  // Convert files to base64
  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result.split(",")[1]); // Solo la parte base64
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const droppedFiles = Array.from(e.dataTransfer.files);
    setFiles((prevFiles) => [...prevFiles, ...droppedFiles]);
    setIsDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDropzoneClick = () => {
    fileInputRef.current.click();
  };

  const handleRemoveFile = (fileName) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName));
  };

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    setSelectedProduct({}); // Resetea el producto al cambiar de categoría
  };

  const handleProductChange = (product) => {
    setSelectedProduct(product);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validación para asegurarse de que se ha seleccionado una categoría y un producto
    if (!selectedCategory.id || !selectedProduct.id) {
      alert("Por favor, selecciona una categoría y un producto.");
      return;
    }

    const images = await Promise.all(
      files.map(async (file) => ({
        name: file.name,
        data: await convertToBase64(file),
      }))
    );

    const payload = {
      category_id: selectedCategory.id,
      product_id: selectedProduct.id,
      images: images,
    };

    try {
      const response = await fetch("https://1e01glq9n4.execute-api.us-east-1.amazonaws.com/uploadimages", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      // Imprimir la respuesta completa de la API
      const responseData = await response.json();
      console.log("Respuesta de la API:", responseData);

      if (response.ok) {
        alert("Imágenes subidas con éxito");
        setFiles([]); // Limpiar la lista de archivos después de subir
      } else {
        alert("Error al subir las imágenes: " + (responseData.message || "Error desconocido"));
      }
    } catch (error) {
      console.error("Error al subir las imágenes:", error);
    }
  };

  return (
    <div className="contenedor-document">
      <h1>Seleccione categoría y producto para subir imágenes</h1>

      <form className="upload-form" onSubmit={handleSubmit}>
        <ReadCategories onCategoryChange={handleCategoryChange} />
        <ReadProduct categoryId={selectedCategory.id} onProductChange={handleProductChange} />
        
        <div
          className={`dropzone ${isDragging ? "dragging" : ""}`}
          onClick={handleDropzoneClick}
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
        >
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileChange}
            className="form-control"
            multiple
            style={{ display: "none" }}
          />
          <span>Arrastra los archivos aquí o haz clic para seleccionar</span>
        </div>

        {files.length > 0 && (
          <div className="file-list">
            <h3>Archivos seleccionados:</h3>
            <ul>
              {files.map((file) => (
                <li key={file.name}>
                  {file.name}
                  <button type="button" onClick={() => handleRemoveFile(file.name)}>
                    Eliminar
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}

        <button type="submit" className="btn-submit">Enviar</button>
      </form>
    </div>
  );
};

export default UploadImages;
