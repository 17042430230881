import React, { useState, useEffect, useCallback } from "react";
import ReadCategories from './ReadCategories';
import ReadProduct from './ReadProduct';
import "./SeeImages.css";
import "./UploadImages.css";

const SeeImages = () => {
  const [selectedCategory, setSelectedCategory] = useState({});
  const [selectedProduct, setSelectedProduct] = useState(null); // Cambiado a null en lugar de {}
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    setSelectedProduct(null); // Resetea el producto al cambiar de categoría
    setImages([]); // Limpia las imágenes cuando se cambia la categoría
    setError(null); // Limpia el error al cambiar de categoría
  };

  const handleProductChange = (product) => {
    setSelectedProduct(product); // Puede ser null si se elimina el producto
    setImages([]); // Limpia las imágenes al cambiar el producto
    setError(null); // Limpia el error al cambiar de producto
  };

  const fetchImages = useCallback(async () => {
    // Verifica que haya category_id y product_id
    if (!selectedCategory.id || !selectedProduct || !selectedProduct.id) {
      setError("Por favor, selecciona una categoría y un producto.");
      return;
    }

    setLoading(true);
    setError(null);
    setImages([]); // Limpia las imágenes antes de cargar nuevas

    try {
      // Construye la URL con category_id y product_id
      const url = `https://1e01glq9n4.execute-api.us-east-1.amazonaws.com/listimg?category_id=${encodeURIComponent(selectedCategory.id)}&product_id=${encodeURIComponent(selectedProduct.id)}`;

      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        const data = await response.json();
        if (data.image_urls && data.image_urls.length > 0) {
          setImages(data.image_urls);
        } else {
          setError("No hay imágenes disponibles para este producto."); // Un solo mensaje de error
        }
      } else {
        setError("No se encontraron imágenes para el producto seleccionado.");
      }
    } catch (error) {
      setError("Ocurrió un error al obtener las imágenes.");
    }

    setLoading(false);
  }, [selectedCategory, selectedProduct]);

  useEffect(() => {
    if (selectedProduct && selectedProduct.id) {
      fetchImages();
    }
  }, [selectedProduct, fetchImages]);

  return (
    <div className="see-images-container">
      <div className="contenedor-document">
        <h1>Seleccione categoría y producto para ver imágenes</h1>
        <ReadCategories onCategoryChange={handleCategoryChange} />
        <ReadProduct categoryId={selectedCategory.id} onProductChange={handleProductChange} />

        {loading && <p>Cargando imágenes...</p>}
        {error && <p style={{ color: "red" }}>{error}</p>}
      </div>
      <div className="images-grid">
        {images.length > 0 ? (
          images.map((image, index) => (
            <div key={index} className="image-item">
              <img src={image} alt={`Imagen ${index + 1}`} />
              <p>{`Imagen ${index + 1}`}</p>
            </div>
          ))
        ) : (
          !loading && !error && <p className="center-text">No se encontraron imágenes para el producto seleccionado.</p>
        )}
      </div>
    </div>
  );
};

export default SeeImages;
